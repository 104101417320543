import Vue from 'vue'
import Vuex from 'vuex'
import Common from './module/Common'

Vue.use(Vuex)

const store = new Vuex.Store({
  namespaced: true,
  modules: {
    Common
  }
});
export default store