import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import i18n from '@/i18n'
import store from './store'
import VueCardFormat from 'vue-credit-card-validation';
// Vue.use(store);

import { Form as ElForm, Card as ElCard, Option as ElOption, Select as ElSelect, Input, FormItem, Dialog as ElDialog, Button as ElButton, InputNumber as ElInputNumber } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElForm);
Vue.use(FormItem);
Vue.use(ElDialog);
Vue.use(ElButton);
Vue.use(ElInputNumber);
Vue.use(ElSelect);
Vue.use(ElCard);
Vue.use(ElOption);
Vue.use(Input);
Vue.use(VueCardFormat);

Vue.config.productionTip = false

// import VueDebugger from 'vue-debugger'
// import 'vue-debugger/dist/vue-debugger.css'

// Vue.use(VueDebugger)

import {
  Button,
  Toast,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton,
  Empty,
  Loading,
  Form,
  Field,
  DropdownMenu,
  DropdownItem,
  NavBar,
  Cell,
  CellGroup,
  ActionSheet,
  Row,
  Card,
  Notify,
  Divider,
  Dialog,
  PasswordInput, NumberKeyboard
} from 'vant';

Vue.use(Divider);
Vue.use(Button);
Vue.use(Toast);
Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(GoodsActionIcon);
Vue.use(Empty);
Vue.use(Dialog);
Vue.use(Loading);
Vue.use(Form);
Vue.use(Field);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(NavBar);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(ActionSheet);
Vue.use(Row);
Vue.use(Card);
Vue.use(Notify);

Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
// // telegram_web_app
// const telegram_web_app = document.createElement('script')
// telegram_web_app.setAttribute('src', 'https://telegram.org/js/telegram-web-app.js')
// document.body.appendChild(telegram_web_app)

import 'vant/lib/index.css';

import "@/assets/scss/style.css";

new Vue({
  el: "#app",
  router: router,
  store,
  i18n,
  render: h => h(App),
})


