<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
import ApiAccount from "@/api/account";

export default {
	name: "App",
	data() {
		return {};
	},
	async mounted() {
		this.$nextTick(() => {
			window.Telegram.WebApp.expand();
		});

		var url = new URL(location.href);
		var chatId = url.searchParams.get("chatId");

		if (chatId) {
			localStorage.setItem("TelegramBotChatId", chatId);
		}
		let initData = await window.Telegram.WebApp.initData;
		// if (!window.Telegram.WebApp.initData) {
		// 	initData = `query_id=AAEzFp0YAAAAADMWnRi8_yF0&user=%7B%22id%22%3A412948019%2C%22first_name%22%3A%22Vlad%22%2C%22last_name%22%3A%22Volodko%22%2C%22username%22%3A%22ruless95%22%2C%22language_code%22%3A%22ru%22%7D&auth_date=1666094352&hash=051bdae18f390637a7af92ba4442e477a86bfc7756c473e2200be99945616241`;
		// }
		localStorage.setItem("TelegramBotInitData", initData);

		await ApiAccount.getToken().then(
			(responce) => {
				this.$toast.clear();
				if (responce.status == 200) {
					localStorage.setItem("token", responce.data.token);
					// window.location.href = "/";
					this.$router.push({ path: "/" });
				} else {
					this.$toast.loading({
						duration: 0,
						message: "Error",
						forbidClick: true
					});
				}
			},
			() => {
				this.$toast.clear();
				this.$router.push({ path: "/register" });
				// window.location.href = "/register";
			}
		);
	}
};
</script>

<style></style>
