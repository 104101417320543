// import ApiWallet from "@/api/wallet"
import ApiAccount from "@/api/account"

export default {
    namespaced: true,

    state: {
        selfUser: {},
        depostitTax: [],
        withdrawTax: [],
        exchangeForm: {},
        exchangeCurrencyPair: {}
    },

    getters: {
        selfUser: (state) => state.selfUser,
        depostitTax: (state) => state.depostitTax,
        withdrawTax: (state) => state.withdrawTax,
        exchangeForm: (state) => state.exchangeForm,
        exchangeCurrencyPair: (state) => state.exchangeCurrencyPair
    },

    mutations: {
        SET_SELF_USER(state, selfUser) {
            state.selfUser = selfUser
        },
        SET_DEPOSIT_TAX(state, depostitTax) {
            state.depostitTax = depostitTax
        },
        SET_WITHDRAW_TAX(state, withdrawTax) {
            state.withdrawTax = withdrawTax
        },
        SET_EXCHANGE_FORM(state, exchangeForm) {
            state.exchangeForm = exchangeForm
        },
        SET_EXCHANGE_CURRENCY_PAIR(state, exchangeCurrencyPair) {
            state.exchangeCurrencyPair = exchangeCurrencyPair;
        }
    },

    actions: {
        remeberExchangeForm({ commit }, params = {}) {
            commit('SET_EXCHANGE_FORM', params);
        },
        getSelfUser({ commit }) {
            console.log(123);
            return ApiAccount.getMe().then(response => {
                console.log(response);
                commit('SET_SELF_USER', response);

                return response
            }).catch((error) => {
                console.log(error);
                return error
            })
        },
    }
}