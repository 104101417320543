console.log(window.location.host);

let API_URL = "https://api.stage.exchangepaid.com/"
if (window.location.host == 'webapp.xpaids.com') {
  API_URL = "https://api.exchangepaid.com/"
}

const config = function () {
  return {
    baseURL: API_URL,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      TelegramBotInitData: localStorage.getItem('TelegramBotInitData'),
      TelegramBotChatId: localStorage.getItem('TelegramBotChatId'),
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
};

export default config;
